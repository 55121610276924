import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
const baseUrl = "https://api-backend.fraxr.cubebase.ai/"

export type StudiesType = {
  order_id: string;
  patient_name: string;
  patient_age: number;
  patient_sex: string;
  date: string;
  referring_doctor: string;
  // mod_study:string;
};

export type PatientType = {
  name: string;
  age: number;
  sex: string;
  phone_no: string;
  ref_doc: string;
  history: string;
  order_id?: string;
  study_link?: string;
  report_link?: string;
  // mod_study:string;
  json?: {
    url:string;
    observations: string[];
    impression: string[];
    pathologies:any[];
  };
};

export type StudiesOutputType = {
  // message: string;
  data: StudiesType[];
};

export const requestStudiesList = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>("requestStudiesList", async (_, thunkAPI:any) => {
  try {
    // const state = thunkAPI.getState(); 
    // const tableName = state.OsteroCheck.tableName; 
    const response = await fetch(
      `${baseUrl}/data`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data.data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const requestReport = createAsyncThunk<
  any,
  string,
  { rejectValue: string }
>("requestReport", async (resData, thunkAPI:any) => {
  // const state = thunkAPI.getState(); 
  // const tableName = state.OsteroCheck.tableName; 
  try {
    console.log(resData);
    const response = await fetch(
      `${baseUrl}/get-info/${resData}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const downloadReport = createAsyncThunk<
  { "report_link": string },
  string | undefined,
  { rejectValue: string }
>("downloadReport", async (resData, thunkAPI) => {
  try {
    console.log(resData);
    const response = await fetch(
      `${baseUrl}/report/get-report?order_id=${resData}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const requestAddPatient = createAsyncThunk<
  any,
  { patient_data: PatientType; formData: FormData },
  { rejectValue: string }
>("requestAddPatient", async (data, thunkAPI:any) => {
  try {
    // const state = thunkAPI.getState(); 
    // const mod_study = state.OsteroCheck.mod_study; 
    console.log()

    var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "patient_name": data.patient_data.name,
      "referring_doctor": data.patient_data.ref_doc,
      "patient_age": data.patient_data.age.toString(),
      "patient_sex": data.patient_data.sex,
      "patient_mobile": data.patient_data.phone_no,
      "patient_history": data.patient_data.history,
      "filename": data.formData.getAll("filename")[0],
      // "mod_study": data.patient_data.mod_study, 
    });
    console.log("insert api data",raw);

    console.log(data.formData.getAll("patient_age"));

    const response = await fetch(
      `${baseUrl}/insert-chestr`,
      {
        headers: myHeaders,
        method: "POST",
        body: raw


      }
    );
    console.log("post api data",data.formData)
    const resData = await response.json();
    console.log("result data",resData)
    if (resData.details) {
      return "Please fill the patient details properly";
    }
    console.log({ ...resData, json: resData });

    return { ...resData, json: resData.json };
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

interface OsteroCheckSliceState {
  patientList: {
    data: StudiesOutputType;
    loading: boolean;
    error: string | null;
  };
  patientData: { data: PatientType; loading: boolean; error: string | null };
  report: { loading: boolean; error: string | null };
  downloadReport: { loading: boolean; error: string | null };
  // mod_study?: string; 
}

const initialState: OsteroCheckSliceState = {
  patientList: {
    data: {data: [] },
    loading: false,
    error: null
  },
  patientData: {
    data: {
      name: "",
      age: 0,
      sex: "",
      phone_no: "",
      ref_doc: "",
      history: "",
      study_link: "",
      report_link: "",
      // mod_study: "",
      json: {  url:"", observations: [], impression: [] ,pathologies: []}
    },
    loading: false,
    error: null
  },
  report: { loading: false, error: null },
  downloadReport: { loading: false, error: null }
};

console.log()

export const OsteroCheckSlice = createSlice({
  name: "requestStudiesList",
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<PatientType>) => {
      state.patientData.data = { ...state.patientData.data, ...action.payload };
    },
    //  modStudy: (state, action: PayloadAction<string>) => {
    //   state.mod_study = action.payload;
    // }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestStudiesList.pending, (state) => {
        state.patientList.loading = true;
        state.patientList.error = null;
      })
      .addCase(requestStudiesList.fulfilled, (state, action) => {
        state.patientList.loading = false;
        state.patientList.data = action.payload;
      })
      .addCase(requestStudiesList.rejected, (state, action) => {
        state.patientList.loading = false;
        state.patientList.error =
          action.error.message || "Something went wrong";
      })

      // =====================================================

      .addCase(requestReport.pending, (state) => {
        state.patientData.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestReport.fulfilled, (state, action) => {
        state.patientData.loading = false;
        state.patientData.data.name = action.payload.data[0].patient_name;
        state.patientData.data.sex = action.payload.data[0].patient_sex;
        // state.patientData.data.mod_study=action.payload.data[0].mod_study;
        state.patientData.data.history = action.payload.data[0].history;
        state.patientData.data.age = action.payload.data[0].patient_age;
        state.patientData.data.report_link = action.payload.data[0].report_link
        state.patientData.data.phone_no =
          action.payload.data[0].patient_mobile;
        state.patientData.data.ref_doc =
          action.payload.data[0].referring_doctor;

        state.patientData.data.order_id = action.payload.data[0].order_id;
        state.patientData.data.json = action.payload.data[0].json;
      })
      .addCase(requestReport.rejected, (state, action) => {
        state.patientData.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      })

      // =====================================================

      .addCase(requestAddPatient.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestAddPatient.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.json = action.payload.json;
        state.patientData.data.study_link = action.payload.study_link;
        state.patientData.data.report_link = action.payload.report_link;
        console.log("json ",action.payload.json)
      })
      .addCase(requestAddPatient.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      })

      // =====================================================

      .addCase(downloadReport.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.report_link = action.payload.report_link;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      });
      
      
  }
});

export const { addPatient } = OsteroCheckSlice.actions;
export default OsteroCheckSlice.reducer;


import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoginInitialState {
  user: {
    email: string | null;
    uid: string;
    displayName: string | null;
    phoneNumber: string | null;
  } | null;
  isAuthenticated?: boolean;
  isLoading?: boolean;
}
const initialState: LoginInitialState = {
  user: null,
  isAuthenticated: false,
  isLoading: true
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginInitialState["user"]>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
    }
  }
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;

import React from "react";

interface TableProps {
  headers: string[];
  data: any[];
  tableRow: (row: any, i: number) => JSX.Element;
}

const TableHead = ({ header }: { header: string }) => {
  return (
    <div className="bg-gray-200 p-2   font-semibold text-center">{header}</div>
  );
};

const Table = (props: TableProps) => {
  const { headers, data, tableRow } = props;
  console.log("table props",data);

  return (
    <div className="rounded-lg overflow-hidden border ">
      <div className="grid grid-cols-6 px-4 py-1 bg-gray-200">
        {headers.map((header, index) => (
          <TableHead key={index} header={header} />
        ))}
      </div>

      <div className=" overflow-y-auto h-[70vh]">
        {data?.map((row, index) => {
          return tableRow(row, index);
        })}
      </div>
    </div>
  );
};

export default Table;

import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const PatientData = () => {
  const patientData = useSelector(
    (state: RootState) => state.OsteroCheck.patientData
  );

  return (
    <div
      className=" shadow-md rounded 
     rounded-2xl  p-8 overflow-x-auto overflow-y-auto 
   bg-clean-white border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300"
    >
      <p className="font-bold pb-4">Patient Information</p>

      <div className="w-full">
        <table className="min-w-full  ">
          <tbody className="bg-white  text-sm">
            <tr className="overflow-y-auto whitespace-nowrap">
              <td className=" whitespace-nowrap pr-2 w-1/3 text-sm text-gray-500">
                Name{" "}
              </td>
              <td className=" whitespace-nowrap font-medium text-grayscale-dark-2">
                : {patientData.data.name}
              </td>
            </tr>
            <tr>
              <td className=" whitespace-nowrap pr-2 w-1/3 text-sm  text-gray-500">
                Age{" "}
              </td>
              <td className=" whitespace-nowrap font-medium text-grayscale-dark-2">
                : {patientData.data.age}
              </td>
            </tr>
            <tr>
              <td className=" whitespace-nowrap pr-2 w-1/3 text-sm text-gray-500">
                Sex{" "}
              </td>
              <td className=" whitespace-nowrap font-medium text-grayscale-dark-2">
                : {patientData.data.sex}
              </td>
            </tr>
            <tr>
              <td className=" whitespace-nowrap pr-2 w-1/3 text-sm text-gray-500">
                Phone Number
              </td>
              <td className=" whitespace-nowrap font-medium text-grayscale-dark-2">
                : {patientData.data.phone_no}
              </td>
            </tr>
            <tr>
              <td className=" whitespace-nowrap pr-2 w-1/3 text-sm text-gray-500">
                Referring Doctor
              </td>
              <td className=" whitespace-nowrap font-medium text-grayscale-dark-2">
                : {patientData.data.ref_doc}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientData;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login, logout } from "../redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { withFormik, FormikProps, FormikErrors, Form, Field } from "formik";
import {
  auth,
  onAuthStateChanged,
  signInWithEmailAndPassword
} from "../utils/firebase";
interface FormValues {
  email: string;
  password: string;
}

interface LoginFormikProps {}

const Login = () => {
  const [isloggedIn, setIsLoggedIn] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        console.log(userAuth);
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            phoneNumber: userAuth.phoneNumber
          })
        );
        navigate("/list");
      } else {
        dispatch(logout());
        navigate("/");
      }
    });
  }, []);

  const LoginForm = (props: FormikProps<FormValues>) => {
    const { touched, errors, isSubmitting } = props;
    return (
      <Form>
        <div className="mb-6">
          <label className="text-black text-sm  mb-4 " htmlFor="username">
            Username
          </label>
          <Field
            type="email"
            name="email"
            className=" border border-solid border-neutral-5 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            placeholder="Username"
          />

          {touched.email && errors.email && (
            <p className="text-red-500 text-xs italic">{errors.email}</p>
          )}
        </div>
        <div className="mb-10">
          <label className="text-black text-sm  mb-2" htmlFor="password">
            Password
          </label>
          <Field
            type="password"
            name="password"
            className="rounded w-full border border-solid border-neutral-5 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            placeholder="******************"
          />
          {touched.password && errors.password && (
            <p className="text-red-500 text-xs italic">{errors.email}</p>
          )}
        </div>
        <div className="items-center justify-between">
          <button
            type="submit"
            disabled={isSubmitting}
            className=" w-full bg-logo-blue hover:bg-gray-300 hover:text-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Sign In
          </button>
        </div>
      </Form>
    );
  };

  const LoginFormik = withFormik<LoginFormikProps, FormValues>({
    mapPropsToValues: (props) => {
      return {
        email: "",
        password: ""
      };
    },

    validate: (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = "Required *";
      }
      return errors;
    },

    handleSubmit: (values) => {
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(
          (userAuth: {
            user: {
              email: string | null;
              uid: string;
              displayName: string | null;
              phoneNumber: string | null;
            };
          }) => {
            dispatch(
              login({
                email: userAuth.user.email,
                uid: userAuth.user.uid,
                displayName: userAuth.user.displayName,
                phoneNumber: userAuth.user.phoneNumber
              })
            );
            navigate("/list");
          }
        )
        .catch((err) => {
          setIsLoggedIn(false);
          navigate("/");
        });
    }
  })(LoginForm);

  return (
    <div
      className="object-cover grid justify-center items-center h-screen w-screen bg-cover bg-no-repeat  bg-center "
      style={{ backgroundImage: "url(/img/login_bg.png)" }}>
      {!isloggedIn && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert">
          <span className="block sm:inline">
            Incorrect username or password{" "}
          </span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setIsLoggedIn(true)}>
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
      <div className="grid justify-center bg-white shadow-md rounded rounded-2xl bg-clean-white shadow-[4px_4px_4px_rgba(0,_0,_0,_0.1)] pb-14 pr-14 pl-14">
        <div className="w-60 h-[7rem] mt-[2rem] ">
          <img
            src="/img/logo.png"
            className="object-contain w-full h-full"
            alt=""
          />
        </div>

        <LoginFormik />
      </div>
    </div>
  );
};

export default Login;

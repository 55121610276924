import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "./AuthSlice";
import OsteroCheckSlice from "./OsteroCheckSlice";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: {
    login: LoginReducer,
    OsteroCheck: OsteroCheckSlice
  }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
